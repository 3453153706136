.success_page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.success_page-logo {
    width: 250px;
    margin-top: 50px;
    margin-bottom: 30px;
}

.success_page-title {
    text-align: center;
    font-size: 2.2rem;
}

.success_page-blurb {
    font-size: 1.3rem;
    text-align: center;
}

.success_page-btn {
    border: none;
    background-color: var(--brown-orange);
    color: white;
    text-transform: uppercase;
    height: 50px;
    padding: 15px 32px 15px 32px;
    margin-left: 15px;
    letter-spacing: 1px;
    transition: background-color 800ms;
    margin-top: 50px;
    margin-bottom: 50px;
}

/* ///////////// hover ////////////// */

.success_page-btn:hover {
    cursor: pointer;
    background-color: black;
}