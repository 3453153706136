.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.footer-company-name-container {
    position: relative;
    width: fit-content;
    color: white;
    margin-top: 0px;
    padding-top: 40px;
    margin-bottom: 46px;
    border-top: 6px solid var(--brown-orange);
    
}

.footer-company-name-link {
    text-decoration: none;
}

.footer-company-name {
    font-size: 2.2rem;
    font-family: "PT Sans Narrow", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 18px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 10px;
}

.footer-company-name-lower {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 1.2rem;
    overflow: hidden;
    text-align: center;
    transform: scaleY(.8);
}

.footer-company-name-lower::before,
.footer-company-name-lower::after {
    background-color: white;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 14%;
}

.footer-company-name-lower::before {
    right: 0.1em;
    margin-left: -50%;
}

.footer-company-name-lower::after {
    left: 0.1em;
    margin-right: -50%;
}

.footer-links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
}

.footer-link {
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 700;
    letter-spacing: 1px;
    transition: color 800ms;
}

.footer-copyright-text {
    color: var(--light-grey);
    opacity: .7;
    font-size: .9rem;
    margin-bottom: 30px;
}

.footer-about-text {
    color: var(--light-grey);
    opacity: .7;
    font-size: .9rem;
    width: 90%;
    text-align: center;
    max-width: 400px;
}

.footer-links-container-desktop {
    display: none;
}

/* ////////////// hover /////////////////// */

.footer-link:hover {
    color: var(--brown-orange);
}

/* /////////////// media query ////////////  */

@media (min-width: 850px) {
    .footer {
        align-items: flex-start;
        padding-left: 40px;
    }

    .footer-links-container {
        flex-direction: row;
        gap: 20px;
        margin-top: 0px;
    }

    .footer-company-name-container {
        margin-bottom: 35px;
    }

    .footer-about-text {
        text-align: left;
        max-width: 650px;
        margin-bottom: 40px;
    }
}

@media (min-width: 1450px) {

    .footer {
        padding-left: 100px;
        padding-right: 100px;
    }
   .footer-links-container {
        display: none;
   }

   .footer-links-container-desktop {
        display: inherit;
        gap: 25px;
    }   

    .link-logo-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}