.category_card {
    width: 90%;
    height: 165px;
    background-color: var(--light-grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 80px;
    margin-bottom: 30px;
    border-radius: 8px;
    max-width: 350px;
    
}

.category_card-img {
    width: 220px;
    position: absolute;
    top: -85px;
}

.category_card-name {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    margin-top: 75px;
    margin-bottom: 0px;
    font-weight: 700;
    z-index: 1;
}

.category_card-shop-btn {
    border: none;
    background-color: var(--light-grey);
    font-size: .8rem;
    color: var(--grey);
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 0px;
    text-decoration: none;
    font-weight: 700;
    transition: color 800ms;
    padding: 10px 30px 10px 30px;
    margin: -10px -30px, -10px -30px;
    z-index: 0;
    text-align: center;
}

.category_card-orange-arrow {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--brown-orange);
}


/* ///////////////// hover /////////////////// */

.category_card-shop-btn:hover {
    cursor: pointer;
    color: var(--brown-orange);
}

/* /////////////// media query //////////////// */

@media (min-width: 850px) {
    .category_card {
        width: 30%;
    }

    .category_card-img {
        width: 170px;
        top: -60px;
    }
}

@media (min-width: 1450px) {
    .category_card {
        min-width: 400px;
        height: 200px;
    }

    .category_card-img {
        width: 220px;
        top: -70px;
    }

    .category_card-name {
        font-size: 1.1rem;
    }

    .category_card-shop-btn {
        font-size: .85rem;
    }
}