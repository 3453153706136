
.product_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px
}

.product_detail-img-container {
    background-color: var(--light-grey);
    padding: 25px;
    border-radius: 10px;
    width: 100%;
}

.product_detail-img {
    max-width: 500px;
    margin: 0 auto;
}

.product_detail-content-container {
    text-align: left;
}

.product_detail-name {
    text-transform: uppercase;
    font-size: 1.7rem;
    margin-top: 0px;
    max-width: 280px;
}

.product_detail-desc {
    color: var(--grey);
    line-height: 24px;
}

.important-span {
    font-weight: 700;
    color: var(--brown-orange);
}

.product_detail-price {
    font-weight: bold;
    font-size: 1.2rem;
}

.product_detail-btns-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.product_detail-add-minus-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.product_detail-product-quantity {
    background-color: var(--light-grey);
    height: 50px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.minus-btn,
.plus-btn {
    border: none;
    height: 50px;
    width: 40px;
    background-color: var(--light-grey);
    font-size: 1.2rem;
    color: var(--grey);
    transition: color 400ms;
}


.minus-btn {
    font-size: 1.3rem;
}

.product_detail-add-cart-btn {
    border: none;
    background-color: var(--brown-orange);
    color: white;
    text-transform: uppercase;
    height: 50px;
    padding: 15px 32px 15px 32px;
    margin-left: 15px;
    letter-spacing: 1px;
    transition: background-color 800ms;
}

.product_detail-dimensions-container {
    width: 100%;
    text-align: left;
}

.product_detail-dimensions-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
}

.product_detail-dimensions-inner-container {
    display: flex;
    gap: 18px;
}

.product_detail-dimensions-text {
    font-size: 1.1rem;
    color: var(--grey);
}

.product_detail-dimensions-amount {
    color: var(--brown-orange);
    font-size: 1.1rem;
    font-weight: 600;
}

.product_detail-related-products-container {
    width: 100%;
    max-width: 500px;
    margin-top: 70px;
    margin-bottom: 50px;
}

.product_detail-related-products {
    display: flex;
    flex-direction: column;
}

.product_detail-related-products-text {
    text-align: center;
    text-transform: uppercase;
}

.product-choice-section {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.product-choice-inner-container {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 25px;
}

.choice-radio-btn {
    position: absolute;
    z-index: -1;
    
}

.choice {
    margin-bottom: 10px;
}

.choice-radio-btn-label {
    padding: 6px 11px 6px 11px;
    border: 1.3px solid black;
    border-radius: 5px;
    font-size: .8rem;
    background-color: white;
}

.product-choice-section input[type="radio"]:checked + label {
    background-color: var(--brown-orange);
    color: white;
    border: 1.3px solid var(--brown-orange);
}

/* ////////////// hover ////////////// */

.minus-btn:hover,
.plus-btn:hover {
    cursor: pointer;
    color: var(--brown-orange);
}

.product_detail-add-cart-btn:hover {
    cursor: pointer;
    background-color: black;
}

.choice-radio-btn-label:hover {
    cursor: pointer;
}

/* ////////////// media query ////////////////// */

@media (min-width: 850px) {

    .product_detail-img-container {
        height: 100%;
        min-height: 560px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product_detail-img {
        width: 280px;
    }

    .product_detail-flex-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }

    .product_detail-form {
        display: flex;
        flex-direction: column;
    }

    .product_detail-related-products {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
    }

    .product_detail-dimensions-container {
        display: flex;
        justify-content: space-between;
    }

    .product_detail-dimensions-inner-container {
        margin-right: 20px;
        margin-left: 100px;
    }

    .product_detail-related-products-container {
        min-width: 100%;
    }

    .product_detail-related-products {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
    }
    
}

@media (min-width: 1450px) {

    .product_detail {
        padding-right: 100px;
        padding-left: 100px;
        margin-top: 50px;
    }
    .product_detail-img {
        width: 400px;
    }

    .product_detail-flex-container {
        gap: 100px;
    }
    
}