.category_cards-container {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media (min-width: 850px) {
    .category_cards-container {
        display: flex;
        gap: 20px;
        flex-direction: row;
    }
}