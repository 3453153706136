.product_list_one {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product_list_one-category-name-container {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    height: 100px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.empty-category-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.empty-category-title {
    font-size: 2.3rem;
    margin-bottom: 5px;
    margin-top: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: underline var(--brown-orange);
}

.empty-category-text {
    max-width: 400px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 100px;
    color: var(--grey);
}

.empty-category-logo {
    width: 250px;
}

/* ///////////// media query ////////////////// */

@media (min-width: 850px) {
    .product_list_one-category-name-container {
        height: 150px;
    }

    .product_list_one-category-name {
        font-size: 2rem;
    }
}