.checkout-container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.checkout-title {
    margin-top: 20px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.8rem;
}

.checkout-pay-btn {
    border: none;
    background-color: var(--brown-orange);
    color: white;
    text-transform: uppercase;
    height: 50px;
    padding: 15px 32px 15px 32px;
    letter-spacing: 1px;
    transition: background-color 800ms;
    width: 100%;
    margin-top: 25px;
}

.checkout-form-container {
    width: 100%;
}

/* /////////////// hover ///////////////// */

.checkout-pay-btn:hover {
    cursor: pointer;
    background-color: black;
}

/* /////////////// media query ////////////// */ 

@media (min-width: 850px) {
    .checkout-container {
        padding-left: 100px;
        padding-right: 100px;
    }
}