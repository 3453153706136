
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    height: 95px;
    border-bottom: 1px solid black;
    background-color: #ffffff;
    position: absolute;
    z-index: 100;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    padding-bottom: 5px;
}

.shopping-cart-icon {
    width: 22px;
    height: 22px;
    transition: color, 600ms;
}

.shopping-cart-icon-container {
    position: relative;
}

.shopping-cart-icon-num-badge {
    position: absolute;
    top: -27px;
    right: -6px;
    background-color: var(--brown-orange);
    width: 20px;
    height: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-size: .8rem;
}

.header-company-name-link-container {
    position: relative;
    text-decoration: none;
    color: black;
}

.muddy-footprint-svg {
    position: absolute;
    height: 70px;
    top: -5px;
    left: 98%;
    width: 55px;
    
}

.header-company-name {
    font-size: 2.2rem;
    font-family: "PT Sans Narrow", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 18px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 10px;
    z-index: 6;
}

.header-company-name-lower {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 1.2rem;
    overflow: hidden;
    text-align: center;
    transform: scaleY(.8);
}

.header-company-name-lower::before,
.header-company-name-lower::after {
    background-color: #000;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 14%;
}

.header-company-name-lower::before {
    right: 0.1em;
    margin-left: -50%;
}

.header-company-name-lower::after {
    left: 0.1em;
    margin-right: -50%;
}

.header-link {
    text-decoration: none;
    text-transform: uppercase;
    font-size: .95rem;
    font-weight: 700;
    letter-spacing: 1px;
    transition: color 800ms;
    color: black;
    margin-right: 20px;
}

/* /////////////////// hover //////////////////////// */

.shopping-cart-icon:hover {
    cursor: pointer;
    color: var(--brown-orange);
}

.shopping-cart-icon-num-badge:hover {
    cursor: pointer;
}

.header-link:hover {
    color: var(--brown-orange);
}

.header-link-container {
    display: none;
}


/* /////////////// media query ///////////////// */

@media (min-width: 850px) {
    .shopping-cart-icon {
        height: 30px;
        width: 30px;
    }

    .shopping-cart-icon-num-badge {
        top: -27px;
        width: 22px;
        height: 22px;
        font-size: .9rem;
    }
}

@media (min-width: 1450px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        padding-left: 80px;
        padding-right: 80px;
    }

    .hamburger {
        display: none;
    }

    .hamburger-inner {
        display: none;
    }

    .hamburger-box {
        display: none;
    }

    .header-company-name-link-container {
        order: 0;
    }

    .shopping-cart-icon-container {
        order: 2;
    }

    .header-link-container {
        display: inherit;
        order: 1;
        gap: 25px;
    }
}