.product_list_all {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product_list_all-category-name-container {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    height: 100px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}

/* ////////////////// media query ////////////////////// */

@media (min-width: 850px) {
    .product_list_all-category-name {
        font-size: 2rem;
    }

    .product_list_all-category-name-container {
        height: 150px;
    }
}