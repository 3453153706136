.burger_menu_modal {
    position: absolute;
    z-index: 5;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 10px 10px;
    top: 5px;
    padding-top: 30px;
}

.burger_menu {
    position: absolute;
    width: 100%;
    left: 0px;
}

