
:root {
    --brown-orange: #c8691c;
    --grey: #6c757d;
    --light-grey: #e9ecef;
}

* {
    box-sizing: border-box;
    
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

img {
    display: block;
    width: 100%;
}



body {
    margin: 0px;
    padding: 0px;
    font-family: "Manrope", sans-serif;
}

.burger_menu_modal-page-cover {
    background-color: rgba(0, 0, 0, .5);
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    position: fixed;
    background-size: cover;
    z-index: 1;

}

.header-space {
    height: 90px;
}


button:hover {
    cursor: pointer;
}