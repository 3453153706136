
.product_card {
    width: 90%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}

.product_card-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product_card-img-container {
    background-color: var(--light-grey);
    border-radius: 10px;
    width: 100%;
    padding: 20px;
}

.product_card-img {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.product_card-name {
    max-width: 300px;
    margin-top: 20px;
    margin-bottom: 0px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.product_card-desc {
    max-width: 400px;
    padding: 10px;
    color: var(--grey);
    margin-top: 5px;
}

.product_card-link {
    background-color: var(--brown-orange);
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    padding: 15px 30px 15px 30px;
    transition: background-color 800ms;
}


/* ////////////// hover /////////////// */

.product_card-link:hover {
    background-color: black;
}

/* /////////////// media query ///////////// */

@media (min-width: 850px) {
    .product_card-desc {
        max-width: 700px;
        font-size: 1.2rem;
    }

    .product_card-img-container {
        max-width: 800px;
    }

    .product_card-name {
        max-width: 450px;
    }
}

@media (min-width: 1450px) {
    
    .product_card {
        flex-direction: row;
        gap: 90px;
    }

    .flex-reverse {
        flex-direction: row-reverse
    }

    .product_card-content-container {
        text-align: left;
        align-items: flex-start;
        justify-content: center;
        
    }

    .product_card-desc {
        padding-left: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .product_card-name {
        font-size: 1.9rem;
        margin: 0px;
    }

    .product_card-img-container {
        max-width: 700px;
        height: 520px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product_card-img {
        height: 100%;
        object-fit: cover;
    }
}