.product_card_small {
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.product_card_small-img-container {
    background-color: var(--light-grey);
    border-radius: 10px;
    padding: 20px;
    width: 100%;
}

.product_card_small-img {
    max-width: 300px;
    margin: 0 auto;
    width: 160px;
}

.product_card_small-name {
    text-transform: uppercase;
    max-width: 70%;
    text-align: center;
    font-weight: 800;
}

.product_card_small-link {
    text-transform: uppercase;
    background-color: var(--brown-orange);
    color: white;
    text-decoration: none;
    padding: 15px 30px 15px 30px;
    font-size: .8rem;
    letter-spacing: 1px;
    transition: background-color 800ms;
}

/* ////////////// hover //////////////// */

.product_card_small-link:hover {
    cursor: pointer;
    background-color: black;
}

/* ////////////////////// media query ////////////////////// */

@media (min-width: 850px) {
    .product_card_small {
        min-width: 320px;
    }

    .product_card_small-name {
        max-width: 90%;
    }
}