.home_page  {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_page-cover-img-container {
    position: relative;
    height: 400px;
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home_page-cover-img {
    position: absolute;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.home_page-logo {
    width: 180px;
}

.home_page-logo-container {
    background-color: rgba(255, 255, 255, .8);
    border-radius: 150px;
    height: 210px;
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_page-see-products-btn {
    width: fit-content;
    background-color: black;
    color: white;
    text-decoration: none;
    padding: 15px 25px 15px 25px;
    margin-top: 35px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: .9rem;
    color: white;
    transition-property: background-color, color;
    transition-duration: 800ms;
}

/* //////////////// hover //////////////////// */

.home_page-see-products-btn:hover {
    background-color: var(--brown-orange);
}

/* //////////// media query /////////////// */

@media (min-width: 850px) {
    

    .home_page-cover-img-container { 
        height: 600px;
    }

    .home_page-see-products-btn { 
        padding: 20px 45px 20px 45px;
    }

    .home_page-logo {
        width: 270px;
    }
    
    .home_page-logo-container {
        background-color: rgba(255, 255, 255, .8);
        border-radius: 250px;
        height: 320px;
        width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 1450px) {
    .home_page-cover-img-container { 
        height: 750px;
        /* height: auto; */
    }

    .home_page-logo-container {
        width: 400px;
        height: 400px;
        padding-bottom: 20px;
    }
    .home_page-logo {
        width: 330px;

    }
}