
.contact {
    padding: 25px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-title {
    font-size: 2.5rem;
    margin-bottom: 0px;
    margin-top: 50px;
    text-transform: uppercase;
}

.contact-title-container {
    max-width: 550px;
}

.contact-form {
    max-width: 550px;
    width: 100%;
}

.contact-instructions  {
    margin-top: 0px;
    font-size: .9rem;
    color: var(--grey);
    margin-bottom: 35px;
}

.contact-form-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.contact-form-input-label {
    font-weight: 700;
    font-size: .7rem;
}

.contact-form-input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid black;
    padding-left: 10px;
    transition: border 400ms;
    font-size: .9rem;
}

.contact-form-input-textarea {
    border-radius: 5px;
    border: 1px solid black;
    padding-left: 10px;
    transition: border 400ms;
    font-size: .9rem;
    width: 100%;
    height: 300px;
    max-width: 100%;
    min-width: 100%;
    max-height: 500px;
    min-height: 300px;
    padding-top: 8px;
}

.contact-form-submit-btn  {
    border: none;
    background-color: var(--brown-orange);
    color: white;
    text-transform: uppercase;
    height: 50px;
    padding: 15px 32px 15px 32px;
    letter-spacing: 1px;
    transition: background-color 800ms;
    width: 100%;
    margin-top: 25px;
    font-size: .9rem;
    margin-bottom: 25px;
}

.gotcha {
    display: none;
}

/* //////////////// hover //////////////////// */

.contact-form-input:hover {
    border: 1px solid var(--brown-orange);
}

.contact-form-submit-btn:hover {
    cursor: pointer;
    background-color: black;
}