
.input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
}

.checkout-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.shipping-billing-container {
    width: 100%;
}

.input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid black;
    padding-left: 10px;
    transition: border 400ms;
}

.input::placeholder {
    color: lightgrey;
}

.input-label {
    font-weight: 700;
    font-size: .7rem;
}

.section-title {
    color: var(--brown-orange);
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 18px;
    margin-top: 20px;

}

.checkbox-input {
    width: 18px;
    height: 18px;
    accent-color: var(--brown-orange);
}

.checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 25px;
}

.checkbox-label {
    font-size: .85rem;
    letter-spacing: 1px;
    font-weight: 500;
}


/* /////// checkout items //////// */

.checkout-item-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    margin-bottom: 20px;
    max-width: 700px;
    width: 100%;
}

.checkout-item-card-content {
    display: flex;
}

.checkout-img-container {
    background-color: var(--light-grey);
    height: 65px;
    width: 65px;
    min-width: 65px;
    padding: 5px 10px 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.checkout-item-text-container {
    height: 50px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 65px;
}

.checkout-item-name {
    margin-bottom: 5px;
    margin-top: 0px;
    line-height: 15px;
    font-weight: 600;
}

.checkout-item-price {
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--grey);
    font-weight: 600;
    font-size: .9rem;
}

.checkout-item-count {
    color: var(--grey);
    font-weight: 600;
}

.checkout-pay-btn {
    max-width: 700px;
}

/* //////////////////// total breakdown ///////////// */

.summary-container {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.summary-title {
    font-size: 1.4rem;
}

.total-breakdown-container {
    max-width: 700px;
    width: 100%;
}

.total-breakdown-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 0px;
    height: 35px;
}

.total-name {
    color: var(--grey);
    text-transform: uppercase;
    font-weight: 500;
}

.total-amount {
    font-weight: 700;
    font-size: 1.1rem;
}

.grand-total-amount {
    color: var(--brown-orange);
}

.grand-total-container {
    margin-top: 20px;
}

/* /////////////////// hover ///////////////////// */

.checkbox-input:hover {
    cursor: pointer;
}

.input:hover {
    border: 1px solid var(--brown-orange);
}

/* ////////////// media query ////////////// */

@media (min-width: 850px) {
    
    

    .input-container {
        width: 400px;
        min-width: 300px;
        width: 48%;
    }

    .billing-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .contact-details {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .container {
        width: 100%;
    }

    .shipping-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}