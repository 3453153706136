.cart {
    position: absolute;
    background-color: white;
    z-index: 6;
    top: 120px;
    height: fit-content;
    right: 20px;
    border-radius: 10px;
    padding: 15px 20px 15px 20px;
    margin-left: 120px;
    width: 90%;
    max-width: 400px;
    min-width: 280px;
}

.cart-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.cart-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
}

.cart-remove-all-btn {
    border: none;
    background-color: white;
    color: var(--grey);
    text-decoration: underline;
}

.cart-item-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    margin-bottom: 20px;
}

.cart-item-card-content  {
    display: flex;
}

.cart-item-img-container {
    background-color: var(--light-grey);
    height: 65px;
    width: 65px;
    min-width: 65px;
    padding: 5px 10px 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.cart-item-text-container {
    height: 50px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 65px;
}

.cart-item-name {
    margin-bottom: 5px;
    margin-top: 0px;
    line-height: 15px;
    font-weight: 600;
}

.cart-item-price {
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--grey);
    font-weight: 600;
    font-size: .9rem;
}

.cart-item-count-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.minus-btn-cart,
.plus-btn-cart {
    border: none;
    height: 25px;
    width: 25px;
    background-color: var(--light-grey);
    font-size: 1rem;
    color: var(--grey);
    transition: color 400ms;
}


.minus-btn-cart {
    font-size: 1.1rem;
}

.cart-item-count {
    background-color: var(--light-grey);
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: .8rem;
}

.cart-total-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-total-title {
    text-transform: uppercase;
    color: var(--grey);
    font-size: 1.1rem;
}

.cart-total {
    font-weight: 700;
    font-size: 1.1rem;
}

.cart-checkout-btn {
    width: 100%;
    background-color: var(--brown-orange);
    text-decoration: none;
    border: none;
    color: white;
    height: 45px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 10px;
    transition: background-color, 800ms;
}


/* //////////////// hover ////////////////// */

.minus-btn-cart:hover,
.plus-btn-cart:hover {
    cursor: pointer;
    color: var(--brown-orange);
}

.cart-checkout-btn:hover {
    background-color: black;
}

/* ////////////// media query ////////////////// */

@media (min-width: 850px) {
    .cart {
        max-width: 430px;
    }
}

@media (min-width: 1450px) {
    .cart {
        right: 80px;
        max-width: 450px;
    }
    
}