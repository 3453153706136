.about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 600px;
    margin-top: 40px;
}

.about-img {
    border-radius: 10px;
    max-width: 400px;
    height: fit-content;
}

.about-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 40px;
    margin-bottom: 30px;
    padding-right: 10px;
    letter-spacing: 1px;
}

.about-title-span {
    color: var(--brown-orange);
    font-weight: 800;
}

.about-text {
    font-size: .9rem;
    margin-bottom: 60px;
    color: grey;
    line-height: 22px;
    padding-left: 20px;
    padding-right: 20px;
}

/* /////////// media query /////////////// */

@media (min-width: 850px) {

    .about {
        max-width: 750px;
    }

    .about-text {
        font-size: 1.1rem;
    }

    .about-title {
        font-size: 1.6rem;
    }

    .about-img {
        width: 90%;
        height: 350px;
        object-fit: cover;
        max-width: none;
    }
}

@media (min-width: 1450px) {

    .about {
        flex-direction: row-reverse;
        gap: 50px;
        max-width: 1100px;
        margin-bottom: 80px;
    }

    .about-img {
        height: 450px;
    }

    .about-content {
        text-align: left;
    }

    .about-text {
        padding-left: 0px;
        padding-right: 0px;
    }
}